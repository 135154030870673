<script setup lang="ts">

    import { ViewerSearchQueryResultItem } from '@/client/dto/ViewerSearchQueryResult';
    import { NewsKategorie } from '@/client/dto/NewsKategorie';
    import { Ref, computed, ref, toRef, watch } from 'vue';
    import { computedAsync } from '@vueuse/core'
    import CloseIcon from "@/assets/close_x.svg";
    import { LayoutVariant } from '@/client/dto/LayoutVariant';

    export interface Props {
        data: ViewerSearchQueryResultItem,
        backendUrl : string,
        layoutVariant : LayoutVariant,
        isActive ?: boolean
    }
    const props = withDefaults(defineProps<Props>(), {
        layoutVariant: LayoutVariant.DEFAULT,
        isActive: true
    });
    const isActive = toRef(props, "isActive");

    const emit = defineEmits<{
        (e: "itemDismissed", value: ViewerSearchQueryResultItem)
    }>();

    const videoElementRef = ref<HTMLVideoElement>();
    
    enum MediaItemType {
        IMAGE = "IMAGE",
        VIDEO = "VIDEO"
    }

    const mediaItemUrl : Ref<string> = computed(() => {
        if (props.data?.mediaItem?.id == undefined)
            return undefined;
        return `${props.backendUrl}${props.backendUrl.endsWith('/') ? '' : '/'}api/viewer/media/${props.data.mediaItem.id}`
    });

    const mediaItemContentType : Ref<MediaItemType> = computedAsync(
        async () => {
            
            // übliche image and video file extensions
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
            const videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'webm', 'flv', 'mts'];

            // Wenn Media URL gesetzt
            if (props.data?.mediaItem) {
                
                // Extract the file extension from the URL using a regular expression
                const extensionMatch = props.data.mediaItem?.fileName?.match(/\.([a-zA-Z0-9]{1,5})(?:[?#]|$)/);
                if (extensionMatch && extensionMatch[1]) {
                    const extension = extensionMatch[1].toLowerCase();
                    
                    // Check if the extension matches any image extension
                    if (imageExtensions.includes(extension)) {
                        return MediaItemType.IMAGE;
                    }

                    // Check if the extension matches any video extension
                    if (videoExtensions.includes(extension)) {
                        return MediaItemType.VIDEO;
                    }
                }

                // Fallback Ask Server for Content Typpe
                try {

                    // Fetch HEAD to see ContentType
                    let contenttype = props.data?.mediaItem?.mimeType;

                    // case insensitive check if ContentType starts with "image" 
                    if (contenttype?.match(/image/i)) {
                        return MediaItemType.IMAGE;
                    }

                    // case insensitive check if ContentType starts with "video" 
                    if (contenttype?.match(/video/i)) {
                        return MediaItemType.VIDEO;
                    }

                } catch (e) {
                    console.error(e);
                }
                
            }

            return null;
        },
        null
    );

    const textInhaltParagraphs = computed(
        () => {
            return props.data?.textinhalt
                ?.split(/\r?\n|\r|\n/g)             // LineBreak => Paragraph
                ?.filter(p => p);                   // Leere Absätze ignorieren
        }
    );

    const onCloseButton = async () => {
        emit('itemDismissed', props.data);
    };

    // wenn dieses Item aus inaktiv wechselt, dann soll ein evtl laufendes Video pausiert werden
    watch(isActive, (newValue) => {
        if (newValue === false) {
            if (videoElementRef.value) {
                videoElementRef.value?.pause();
            }
        }
    });

</script>

<template>
    <template v-if="layoutVariant == LayoutVariant.DEFAULT">
        <div class="news-component-item">
            <div class="news-component-item-media" v-if="mediaItemContentType">
                <template v-if="mediaItemContentType == MediaItemType.VIDEO">
                    <video controls :src="mediaItemUrl" ref="videoElementRef" />
                </template>
                <template v-if="mediaItemContentType == MediaItemType.IMAGE">
                    <img :src="mediaItemUrl" />
                </template>
            </div>
            <div class="news-component-item-text">
                <div class="news-component-item-text-inner">
                    <h3 :title="data?.ueberschrift">
                        {{ data?.ueberschrift }}
                    </h3>
                    <p v-for="p in textInhaltParagraphs">
                        {{ p }}
                    </p>
                    <template v-if="data.link?.url">
                        <news-component-item-link-button 
                            :href="data.link.url"
                            :label="data.link.text ?? data.link.url"
                        />
                    </template>                
                </div>
            </div>   

            <news-component-item-close-button 
                @click.prevent="onCloseButton" 
                v-if="data.kategorie == NewsKategorie.WERBUNG"
                class="news-component-item-dismiss-button"
                title="ausblenden">
                <CloseIcon />
            </news-component-item-close-button>

            <div class="news-component-item-tag" slot="label"
                v-if="data.kategorie == NewsKategorie.GESETZLICHEINFO"
            >
                § Information
            </div>

        </div>
    </template>
    <template v-else-if="layoutVariant == LayoutVariant.LOGIN">
        <div class="news-component-item-login">
            <div class="image-container" v-if="mediaItemContentType">
                <template v-if="mediaItemContentType == MediaItemType.VIDEO">
                    <video controls :src="mediaItemUrl" ref="videoElementRef" />
                </template>
                <template v-if="mediaItemContentType == MediaItemType.IMAGE">
                    <img :src="mediaItemUrl" />
                </template>
            </div>
            <h2 class="text-header">
                {{ data?.ueberschrift }}
            </h2>
            <p v-for="p in textInhaltParagraphs" class="text-paragraph">
                {{ p }}
            </p>
        </div>
    </template>
</template>

<style>

    .news-component-item {

        font-family: var(--bmeFontFamily);
        background: var(--bmeWhite);
        color: var(--bmeDarkGrey800);

        position: relative;

        margin: 0;
        padding: 0;
        border: 0;
        border-radius: 1rem;
        box-sizing: border-box;

        overflow: hidden;
        width: 100%;
        height: 100%;

        display: grid;
        grid-auto-columns: minmax(0, 1fr);
        grid-auto-flow: column;
        align-items: stretch;

        .news-component-item-media {
            display: grid;
            justify-items: stretch;
            align-items: stretch;
            overflow: hidden;
            img, video {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                overflow: hidden;
            }
        }

        .news-component-item-text {

            padding: 2rem;
            overflow: auto;
            color: var(--bmeDarkGrey800);

            .news-component-item-text-inner {
                
                min-height: 100%;

                display: grid;
                justify-items: start;
                align-items: start;
                grid-template-rows: auto minmax(0, 1fr) auto;
                gap: 1rem;

                h3 {
                    margin: 0;
                    text-align: left;
                    font-size: var(--bmeH3FontSize);
                    font-weight: var(--bmeH3FontWeight);
                    font-style: var(--bmeH3FontStyle);
                    line-height: var(--bmeH3LineHeight);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 100%;
                }

                p {
                    margin: 0;
                    text-align: left;
                    font-size: var(--bmeBody2FontSize);
                    font-weight: var(--bmeBody2FontWeight);
                    font-style: var(--bmeBody2FontStyle);
                    line-height: var(--bmeBody2LineHeight);
                }

            }

        }

        .news-component-item-dismiss-button {
            position: absolute;
            right: 1rem;
            top: 1rem;
        }

        .news-component-item-tag {
            position: absolute;
            right: 1rem;
            top: 1rem;
            background: var(--bmeLightGrey200);
            color: var(--bmeDarkGrey800);
            padding: 0.75rem 1.25rem;
            border-radius: 0.5rem;
        }
    }

    @media screen and (max-width: 1280px) { /* TODO Confirm Breakpoint */
        .news-component-item {
            grid-auto-columns: unset;
            grid-auto-flow: row;
            grid-template-rows: auto 1fr;
            .news-component-item-media {
                overflow: unset;
                height: 205px;
            }
            max-height: 643px;
            overflow: auto;
        }
    }

    .news-component-item-login {
        font-family: var(--bmeFontFamily);
        display: block;
        .image-container {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 60vh;
            border-radius: 0.5rem;
            img, video {
                object-fit: cover;
                width: 100%;
            }
        }
        .text-header {
            margin: 0;
            margin-bottom: 1.5rem !important;
            margin-top: 5rem !important;
            text-align: center;
            font-size: var(--bmeH2FontSize);
            font-weight: var(--bmeH2FontWeight);
            font-style: var(--bmeH2FontStyle);
            line-height: var(--bmeH2LineHeight);
        }
        .text-paragraph {
            margin: 0;
            margin-bottom: 1rem !important;
            text-align: center;
            font-size: var(--bmeBody2FontSize);
            font-weight: var(--bmeBody2FontWeight);
            font-style: var(--bmeBody2FontStyle);
            line-height: var(--bmeBody2LineHeight);
        }
    }   
</style>