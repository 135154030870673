/**
 * @see https://vuejs.org/guide/extras/web-components#building-custom-elements-with-vue
 */

import { defineCustomElement } from 'vue'

import NewsComponentItemCloseButton from './components/NewsComponentItemCloseButton.ce.vue'
customElements.define('news-component-item-close-button',  defineCustomElement(NewsComponentItemCloseButton))

import NewsComponentItemLinkButton from './components/NewsComponentItemLinkButton.ce.vue'
customElements.define('news-component-item-link-button',  defineCustomElement(NewsComponentItemLinkButton))

import NewsComponentItem from './components/NewsComponentItem.ce.vue'
customElements.define('news-component-item',  defineCustomElement(NewsComponentItem))

import NewsComponentViewer from './components/NewsComponentViewer.ce.vue'
customElements.define('news-component-viewer',  defineCustomElement(NewsComponentViewer))