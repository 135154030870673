import { ViewerSearchQuery } from "./dto/ViewerSearchQuery";
import { ViewerSearchQueryResult } from "./dto/ViewerSearchQueryResult";
import { UpdateViewerLocationCollapseStateCommand } from "./dto/UpdateViewerLocationCollapseStateCommand";
import { DismissViewerNewsItemCommand } from "./dto/DismissViewerNewsItemCommand";

export async function loadMessagesResponseItem(baseUrl: string, query: ViewerSearchQuery) : Promise<ViewerSearchQueryResult> {
    let url = getUrl(baseUrl, "api/viewer/search");
    let body = JSON.stringify(query, null, 2);
    let headers = { 
        "Accept": "application/json",
        "Content-Type": "application/json" 
    }
    let response = await fetch(url, { 
        method: "POST", 
        headers, 
        body
    });
    return await response.json() as ViewerSearchQueryResult;    
}

export async function updateViewerLocationCollapseState(baseUrl: string, cmd: UpdateViewerLocationCollapseStateCommand) : Promise<void> {
    let url = getUrl(baseUrl, "api/viewer/updateViewerLocationCollapseState");
    let body = JSON.stringify(cmd, null, 2);
    let headers = { 
        "Accept": "application/json",
        "Content-Type": "application/json" 
    }
    await fetch(url, { 
        method: "POST", 
        headers, 
        body
    });    
}

export async function dismissViewerNewsItem(baseUrl: string, cmd: DismissViewerNewsItemCommand) : Promise<void> {
    let url = getUrl(baseUrl, "api/viewer/dismissViewerNewsItem");
    let body = JSON.stringify(cmd, null, 2);
    let headers = { 
        "Accept": "application/json",
        "Content-Type": "application/json" 
    }
    await fetch(url, { 
        method: "POST", 
        headers, 
        body
    });    
}

const getUrl = (baseUrl: string, path: string) : string => {
    return `${baseUrl}${baseUrl.endsWith("/") ? "" : "/"}${path}`;
}
