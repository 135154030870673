export enum DisplayLocation {
    "LOGIN" = "LOGIN",
    "ABLESEDIENST" = "ABLESEDIENST",
    "ANALYSE" = "ANALYSE",
    "VERBRAUCHSANALYSE" = "VERBRAUCHSANALYSE",
    "ARCHIV" = "ARCHIV",
    "DASHBOARD" = "DASHBOARD",
    "LIEGENSCHAFT-DASHBOARD" = "LIEGENSCHAFT-DASHBOARD",
    "INTAS" = "INTAS",
    "HEIZKOERPERBEWERTUNG" = "HEIZKOERPERBEWERTUNG",
    "KAIROS" = "KAIROS",
    "KONTAKT" = "KONTAKT",
    "KOSTENERFASSUNG" = "KOSTENERFASSUNG",
    "NUTZERPFLEGE-UVI" = "NUTZERPFLEGE-UVI",
    "POSTBOX" = "POSTBOX",
    "RAUCHMELDER" = "RAUCHMELDER",
    "UVI" = "UVI",
    "ZWISCHENABLESUNG" = "ZWISCHENABLESUNG",
    "TENANT-NUTZEINHEIT-SELECTION" = "TENANT-NUTZEINHEIT-SELECTION",
    "TENANT-UVI-START" = "TENANT-UVI-START"
}